<!-- style="background-color: #eceff4;" -->
<mat-sidenav-container>
  <mat-sidenav #sidenav [(opened)]="opened" [mode]="screen.isDesktop.value ? 'side' : 'over'" opened (mouseenter)="onMenuMouseEnter()" (mouseleave)="onMenuMouseLeave()" style="background: none; border: none;">
    <iml-sidenav-container style="z-index: 165;">
      <div style="text-align: right;">
        <button mat-flat-button>
          Reset All
          <mat-icon>refresh</mat-icon>
        </button>
      </div>
      <div class="filter-header">
        <mat-icon fontSet="material-symbols-outlined">filter_alt</mat-icon>
        <span class="mat-body-strong">Presets</span>
      </div>
      <iml-sidenav-menu [links]="presets" />
      <div class="filter-header">
        <mat-icon fontSet="material-symbols-outlined">tune</mat-icon>
        <span class="mat-body-strong">Customize</span>
      </div>
      <div style="margin-top: 5px; margin-bottom: 0px;">
        <mat-form-field appearance="outline">
          <mat-label>Enter a date range</mat-label>
          <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
            <input matStartDate formControlName="start" placeholder="Start date">
            <input matEndDate formControlName="end" placeholder="End date">
          </mat-date-range-input>
          <!-- <mat-hint>MM/DD/YYYY – MM/DD/YYYY</mat-hint> -->
          <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-date-range-picker #picker></mat-date-range-picker>
          @if (range.controls.start.hasError('matStartDateInvalid')) {
            <mat-error>Invalid start date</mat-error>
          }
          @if (range.controls.end.hasError('matEndDateInvalid')) {
            <mat-error>Invalid end date</mat-error>
          }
        </mat-form-field>
      </div>
      <div class="filter-content">
        <div style="display: flex; flex-direction: row; align-items: center; justify-content: center;">
          <span style="flex: 1;">Insertion Order</span>
          <mat-slide-toggle color="primary" style="flex: 0 0 auto;" />
        </div>
      </div>
      <div class="filter-header">
        <mat-icon fontSet="material-symbols-outlined">page_info</mat-icon>
        <span class="mat-body-strong">Campaign Status</span>
      </div>
      <div class="filter-content">
        <mat-chip-listbox multiple aria-label="Status">
          <mat-chip-option>Live</mat-chip-option>
          <mat-chip-option>Scheduled</mat-chip-option>
          <mat-chip-option>Ended</mat-chip-option>
        </mat-chip-listbox>
      </div>
      <div class="filter-header">
        <mat-icon fontSet="material-symbols-outlined">switch_access_2</mat-icon>
        <span class="mat-body-strong">Campaign Type</span>
      </div>
      <div class="filter-content">
        <mat-chip-listbox multiple aria-label="Status">
          <mat-chip-option>Display</mat-chip-option>
          <mat-chip-option>Search</mat-chip-option>
          <mat-chip-option>Video</mat-chip-option>
        </mat-chip-listbox>
      </div>
    </iml-sidenav-container>
  </mat-sidenav>
  <mat-sidenav-content>
    <div class="iml-page-content-1" [@fadeInOutPage]="getRouteAnimationState()">
      <div class="iml-layout-container">
        <div class="iml-card" style="background: none; border-radius: 0; overflow: initial;">
          <!-- opened && !openInProgress || !opened && openInProgress -->
          <button mat-flat-button [color]="opened ? 'primary' : null" (click)="toggleMenu();" class="iml-filter-btn" style="border-radius: 28px; border: 1px solid black; border-color: var(--iml-primary-color);">
            <!-- <span style="display: inline-flex; flex-direction: row; align-items: center; justify-content: center;"> -->
              Filters
              <mat-icon fontSet="material-symbols-outlined">{{opened ? 'close' : 'tune'}}</mat-icon>
              <!-- <span>Filters</span> -->
            <!-- </span> -->
          </button>
        </div>
        <div class="iml-card">
          <div class="iml-chart--metric-cont">
            <div class="iml-chart-metric iml-chart-blue">
              <div class="mat-caption iml-chart--metric-caption">
                Total Impressions
              </div>
              <div class="mat-h1 iml-chart--metric-value">
                {{totalData.impressions | number: '1.0-0'}}
              </div>
            </div>
            <div class="iml-chart-metric iml-chart--light-blue">
              <div class="mat-caption iml-chart--metric-caption">
                Display Campaigns
              </div>
              <div class="mat-h1 iml-chart--metric-value">
                {{totalData.clicks | number: '1.0-0'}}
              </div>
            </div>
            <div class="iml-chart-metric iml-chart-red">
              <div class="mat-caption iml-chart--metric-caption">
                Video Campaigns
              </div>
              <div class="mat-h1 iml-chart--metric-value">
                {{totalData.ctr | number: '1.0-0'}}
              </div>
            </div>
            <div class="iml-chart-metric iml-chart-green">
              <div class="mat-caption iml-chart--metric-caption">
                Search Campaigns
              </div>
              <div class="mat-h1 iml-chart--metric-value">
                {{totalData.vtc | number: '1.0-0'}}
              </div>
            </div>
          </div>
          <div style="position: relative; width: 100%; height: 30vh; padding: 30px; padding-top: 0; padding-right: 40px;">
            <canvas
              baseChart
              [data]="lineChartData"
              [options]="lineChartOptions"
              [type]="lineChartType"
            ></canvas>
          </div>
          <!-- (chartHover)="chartHovered($event)"
          (chartClick)="chartClicked($event)" -->
        </div>
        <div class="iml-card">
          <div class="iml-card-content iml-content-last">
            <h3 class="mat-body-strong">Campaigns</h3>
          </div>
          <div style="width: 100%; overflow-y: auto;">
            @if (false) {
              <table mat-table matSort (matSortChange)="sortData($event)" class="demo-table">
                <tr>
                  <th mat-sort-header="submitted_by" sortActionDescription="Sort by email"> Submitted By </th>
                  <th mat-sort-header="name" sortActionDescription="Sort by name"> Campaign Name </th>
                  <th mat-sort-header="status" sortActionDescription="Sort by status"> Status </th>
                  <th mat-sort-header="start_date" sortActionDescription="Sort by start date"> Start Date </th>
                  <th mat-sort-header="end_date" sortActionDescription="Sort by end date"> End Date </th>
                  <th mat-sort-header="type" sortActionDescription="Sort by start date"> Campaign Type </th>
                  <th mat-sort-header="impressions" sortActionDescription="Sort by start date"> Impressions </th>
                  <th mat-sort-header="clicks" sortActionDescription="Sort by start date"> Clicks </th>
                  <th mat-sort-header="ctr" sortActionDescription="Sort by start date"> CTR </th>
                  <th mat-sort-header="vtc" sortActionDescription="Sort by start date"> VTC </th>
                </tr>
                @for (element of campaigns; track element) {
                  <tr>
                    <td mat-cell *matCellDef="let element">
                      @if (element.submittedBy) {
                        <!-- {{element.submittedBy?.email}} -->
                        <div class="user-container"
                          matTooltip="{{element.submittedBy.firstName}} {{element.submittedBy.lastName}} {{element.submittedBy.email}} | {{element.submittedBy.company}}">
                          <iml-user-logo
                            [id]="element.submittedBy.id"
                            [firstName]="element.submittedBy.firstName"
                            [lastName]="element.submittedBy.lastName"
                            [photo]="element.submittedBy.logoUrl"
                          />
                          <div class="user-name">
                            @if (element.submittedBy.lastName?.length > 10) {
                              {{element.submittedBy.firstName}}
                            } @else {
                              {{element.submittedBy.firstName[0]}}. {{element.submittedBy.lastName}}
                            }
                          </div>
                        </div>
                      } @else {
                        <div class="user-container">
                          <!-- <div class="user-warning">
                            <mat-icon inline>warning</mat-icon>
                          </div> -->
                          <div class="user-name">
                            <button mat-stroked-button>Assign</button>
                          </div>
                        </div>
                      }
                    </td>
                    <td mat-cell *matCellDef="let element">
                      <a href="javascript:void(0);" class="demo-table-link">{{element.name}}</a>
                    </td>
                    <td mat-cell *matCellDef="let element">
                      @if (element.status === CampaignStatus.Live) {
                        <span class="my-badge full badge-green">Live</span>
                      } @else if (element.status === CampaignStatus.Scheduled) {
                        <span class="my-badge full badge-orange">Scheduled</span>
                      } @else {
                        <span class="my-badge full badge-red">Ended</span>
                      }
                    </td>
                    <td mat-cell *matCellDef="let element"> {{element.startDate | date: 'MMM d, y'}} </td>
                    <td mat-cell *matCellDef="let element"> {{element.endDate | date: 'MMM d, y'}} </td>
                    <td mat-cell *matCellDef="let element"> {{element.type}} </td>
                    <td mat-cell *matCellDef="let element"> {{element.impressions | number}} </td>
                    <td mat-cell *matCellDef="let element"> {{element.clicks | number}} </td>
                    <td mat-cell *matCellDef="let element"> {{element.ctr | number: '1.2-2'}}% </td>
                    <td mat-cell *matCellDef="let element"> {{element.vtc | number: '1.2-2'}}% </td>
                  </tr>
                }
              </table>
            }
            @if (true) {
              <style>
                .table-in-progress td {
                  opacity: 0.5;
                }
              </style>
              <table mat-table [dataSource]="dataSource" matSort (matSortChange)="sortData($event)" class="demo-table" [class.table-in-progress]="inProgress">
                <ng-container matColumnDef="submittedBy">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by email"> Submitted By </th>
                  <td mat-cell *matCellDef="let element">
                    @if (element.submittedBy) {
                      <!-- {{element.submittedBy?.email}} -->
                      @if (element.submittedBy.id) {
                        <div class="user-container"
                          matTooltip="{{element.submittedBy.firstName}} {{element.submittedBy.lastName}} {{element.submittedBy.email}} | {{element.submittedBy.company}}">
                          <iml-user-logo
                            [id]="element.submittedBy.id"
                            [firstName]="element.submittedBy.firstName"
                            [lastName]="element.submittedBy.lastName"
                            [photo]="element.submittedBy.logoUrl"
                          />
                          <div class="user-name">
                            @if (element.submittedBy.lastName?.length > 10) {
                              {{element.submittedBy.firstName}}
                            } @else {
                              {{element.submittedBy.firstName[0]}}. {{element.submittedBy.lastName}}
                            }
                          </div>
                        </div>
                      } @else {
                        <div class="user-container"
                          matTooltip="{{element.submittedBy.company}}">
                          <iml-user-logo
                            [id]="element.submittedBy.company"
                            [firstName]="element.submittedBy.company"
                            [lastName]="element.submittedBy.company"
                          />
                          <div class="user-name">
                            {{element.submittedBy.company}}
                          </div>
                        </div>
                      }
                    } @else {
                      <div class="user-container">
                        <!-- <div class="user-warning">
                          <mat-icon inline>warning</mat-icon>
                        </div> -->
                        <div class="user-name">
                          <button mat-stroked-button>Assign</button>
                        </div>
                      </div>
                    }
                  </td>
                </ng-container>
                <ng-container matColumnDef="name">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by name"> Campaign Name </th>
                  <td mat-cell *matCellDef="let element">
                    <a href="javascript:void(0);" class="demo-table-link">{{element.name}}</a>
                  </td>
                </ng-container>
                <ng-container matColumnDef="status">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by status"> Status </th>
                  <td mat-cell *matCellDef="let element">
                    @if (element.status === CampaignStatus.Live) {
                      <span class="my-badge full badge-green">Live</span>
                    } @else if (element.status === CampaignStatus.Scheduled) {
                      <span class="my-badge full badge-orange">Scheduled</span>
                    } @else {
                      <span class="my-badge full badge-red">Ended</span>
                    }
                  </td>
                </ng-container>
                <ng-container matColumnDef="startDate">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by start date"> Start Date </th>
                  <td mat-cell *matCellDef="let element"> {{element.startDate | date: 'MMM d, y'}} </td>
                </ng-container>
                <ng-container matColumnDef="endDate">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by end date"> End Date </th>
                  <td mat-cell *matCellDef="let element"> {{element.endDate | date: 'MMM d, y'}} </td>
                </ng-container>
                <ng-container matColumnDef="type">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by start date"> Campaign Type </th>
                  <td mat-cell *matCellDef="let element"> {{element.type}} </td>
                </ng-container>
                <ng-container matColumnDef="impressions">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by start date"> Impressions </th>
                  <td mat-cell *matCellDef="let element"> {{element.impressions | number}} </td>
                </ng-container>
                <ng-container matColumnDef="clicks">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by start date"> Clicks </th>
                  <td mat-cell *matCellDef="let element"> {{element.clicks | number}} </td>
                </ng-container>
                <ng-container matColumnDef="ctr">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by start date"> CTR </th>
                  <td mat-cell *matCellDef="let element"> {{element.ctr | number: '1.2-2'}}% </td>
                </ng-container>
                <ng-container matColumnDef="vtc">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by start date"> VTC </th>
                  <td mat-cell *matCellDef="let element"> {{element.vtc | number: '1.2-2'}}% </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
              </table>
            }
          </div>
        </div>
      </div>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>

@if (false) {
  <div class="iml-page-container">
    <iml-sidenav-container style="z-index: 105;">
      <div style="text-align: right;">
        <button mat-flat-button>
          Reset All
          <mat-icon>close</mat-icon>
        </button>
      </div>
      <div class="filter-header">
        <mat-icon fontSet="material-symbols-outlined">filter_alt</mat-icon>
        <span class="mat-body-strong">Presets</span>
      </div>
      <iml-sidenav-menu [links]="presets" />
      <div class="filter-header">
        <mat-icon fontSet="material-symbols-outlined">tune</mat-icon>
        <span class="mat-body-strong">Customize</span>
      </div>
      <div style="margin-top: 5px; margin-bottom: 0px;">
        <mat-form-field appearance="outline">
          <mat-label>Enter a date range</mat-label>
          <mat-date-range-input [rangePicker]="picker">
            <input matStartDate placeholder="Start date">
            <input matEndDate placeholder="End date">
          </mat-date-range-input>
          <!-- <mat-hint>MM/DD/YYYY – MM/DD/YYYY</mat-hint> -->
          <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-date-range-picker #picker></mat-date-range-picker>
        </mat-form-field>
      </div>
      <div class="filter-content">
        <div style="display: flex; flex-direction: row; align-items: center; justify-content: center;">
          <span style="flex: 1;">Insertion Order</span>
          <mat-slide-toggle style="flex: 0 0 auto;" />
        </div>
      </div>
      <div class="filter-header">
        <mat-icon fontSet="material-symbols-outlined">page_info</mat-icon>
        <span class="mat-body-strong">Campaign Status</span>
      </div>
      <div class="filter-content">
        <mat-chip-listbox multiple aria-label="Status">
          <mat-chip-option>Live</mat-chip-option>
          <mat-chip-option>Scheduled</mat-chip-option>
          <mat-chip-option>Ended</mat-chip-option>
        </mat-chip-listbox>
      </div>
      <div class="filter-header">
        <mat-icon fontSet="material-symbols-outlined">switch_access_2</mat-icon>
        <span class="mat-body-strong">Campaign Type</span>
      </div>
      <div class="filter-content">
        <mat-chip-listbox multiple aria-label="Status">
          <mat-chip-option>Display</mat-chip-option>
          <mat-chip-option>Search</mat-chip-option>
          <mat-chip-option>Video</mat-chip-option>
        </mat-chip-listbox>
      </div>
    </iml-sidenav-container>
    <!-- [@fadeInOutPage]="getRouteAnimationState()" -->
    <div class="iml-page-content" [@fadeInOutPage]="getRouteAnimationState()">
      <div class="iml-layout-container">
        <div class="iml-card">
          <img src="assets/images/chat-screen.jpg" style="width: 100%;" />
          <!-- <canvas
            baseChart
            class="chart"
            [data]="lineChartData"
            [options]="lineChartOptions"
            [type]="lineChartType"
            (chartHover)="chartHovered($event)"
            (chartClick)="chartClicked($event)"
          ></canvas> -->
        </div>
        <div class="iml-card">
          <div class="iml-card-content iml-content-last">
            <h3 class="mat-body-strong">Campaigns</h3>
          </div>
          <div style="width: 100%; overflow-y: auto;">
            <table mat-table [dataSource]="dataSource" matSort (matSortChange)="sortData($event)" class="demo-table">
              <ng-container matColumnDef="submittedBy">
                <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by email"> Submitted By </th>
                <td mat-cell *matCellDef="let element">
                  @if (element.submittedBy) {
                    <!-- {{element.submittedBy?.email}} -->
                    <div class="user-container"
                      matTooltip="{{element.submittedBy.firstName}} {{element.submittedBy.lastName}} {{element.submittedBy.email}} | {{element.submittedBy.company}}">
                      <iml-user-logo
                        [id]="element.submittedBy.id"
                        [firstName]="element.submittedBy.firstName"
                        [lastName]="element.submittedBy.lastName"
                        [photo]="element.submittedBy.logoUrl"
                      />
                      <div class="user-name">
                        @if (element.submittedBy.lastName?.length > 10) {
                          {{element.submittedBy.firstName}}
                        } @else {
                          {{element.submittedBy.firstName[0]}}. {{element.submittedBy.lastName}}
                        }
                      </div>
                    </div>
                  } @else {
                    <div class="user-container">
                      <!-- <div class="user-warning">
                        <mat-icon inline>warning</mat-icon>
                      </div> -->
                      <div class="user-name">
                        <button mat-stroked-button>Assign</button>
                      </div>
                    </div>
                  }
                </td>
              </ng-container>
              <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by name"> Campaign Name </th>
                <td mat-cell *matCellDef="let element">
                  <a href="javascript:void(0);" class="demo-table-link">{{element.name}}</a>
                </td>
              </ng-container>
              <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by status"> Status </th>
                <td mat-cell *matCellDef="let element">
                  @if (element.status === CampaignStatus.Live) {
                    <span class="my-badge full badge-green">Live</span>
                  } @else if (element.status === CampaignStatus.Scheduled) {
                    <span class="my-badge full badge-orange">Scheduled</span>
                  } @else {
                    <span class="my-badge full badge-red">Ended</span>
                  }
                </td>
              </ng-container>
              <ng-container matColumnDef="startDate">
                <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by start date"> Start Date </th>
                <td mat-cell *matCellDef="let element"> {{element.startDate | date: 'MMM d, y'}} </td>
              </ng-container>
              <ng-container matColumnDef="endDate">
                <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by end date"> End Date </th>
                <td mat-cell *matCellDef="let element"> {{element.endDate | date: 'MMM d, y'}} </td>
              </ng-container>
              <ng-container matColumnDef="type">
                <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by start date"> Campaign Type </th>
                <td mat-cell *matCellDef="let element"> {{element.type}} </td>
              </ng-container>
              <ng-container matColumnDef="insertionOrder">
                <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by start date"> Insertion Order </th>
                <td mat-cell *matCellDef="let element">
                  @if (element.insertionOrder) {
                    <span class="my-badge badge-primary">I/O</span>
                  }
                </td>
              </ng-container>
              <ng-container matColumnDef="impressions">
                <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by start date"> Impressions </th>
                <td mat-cell *matCellDef="let element"> {{element.impressions | number}} </td>
              </ng-container>
              <ng-container matColumnDef="clicks">
                <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by start date"> Clicks </th>
                <td mat-cell *matCellDef="let element"> {{element.clicks | number}} </td>
              </ng-container>
              <ng-container matColumnDef="ctr">
                <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by start date"> CTR </th>
                <td mat-cell *matCellDef="let element"> {{element.ctr | number: '1.2-2'}}% </td>
              </ng-container>
              <ng-container matColumnDef="vtc">
                <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by start date"> VTC </th>
                <td mat-cell *matCellDef="let element"> {{element.vtc | number: '1.2-2'}}% </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
}
