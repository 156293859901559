import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatMenuModule } from '@angular/material/menu';
import { MatDividerModule } from '@angular/material/divider';
import { UserLogoComponent } from '../../../shared/user-logo/user-logo.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { User } from '../../services/user.service';
import { AuthService } from '../../services/auth.service';
import { ScreenService } from '../../services/screen.service';
import { ActivatedRoute, NavigationExtras, Params, Router } from '@angular/router';

@Component({
  selector: 'iml-header',
  standalone: true,
  imports: [
    UserLogoComponent,
    ReactiveFormsModule,
    MatIconModule,
    MatButtonModule,
    MatToolbarModule,
    MatFormFieldModule,
    MatInputModule,
    MatMenuModule,
    MatTooltipModule,
    MatDividerModule,
  ],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss'
})
export class HeaderComponent implements OnInit {
  user!: User;

  searchForm = new FormGroup({
    query: new FormControl(''),
  });

  @Output() menuOpened = new EventEmitter<void>();

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private auth: AuthService,
    public screen: ScreenService,
  ) {
    this.user = this.auth.user.get();
  }

  ngOnInit(): void {
    this.route.queryParams
      .subscribe(async (params: Params) => {
        console.log('params', params['q']);
        this.searchForm.controls.query.setValue(params['q'] ?? '', { emitEvent: false });
      }
    );
  }

  openMenu() {
    this.menuOpened.emit();
  }

  search() {
    const options: NavigationExtras = {};
    const { query } = this.searchForm.value;

    console.log('SEARCH', this.searchForm.value);
    if (typeof query !== 'undefined' && query !== null && query.length > 0) {
      options.queryParams = {
        q: query,
      };
    }

    this.router.navigate(['/search'], options);
  }

  logout() {
    this.auth.logout();
  }
}
