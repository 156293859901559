import { HttpErrorResponse, HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { EMPTY, catchError, empty, throwError } from 'rxjs';

import { API_PATH, AUTH_PATH, PORTAL_API_PATH } from '../../../constants';

import { TokenService } from '../services/token.service';
import { AuthService } from '../services/auth.service';

export const tokenInterceptor: HttpInterceptorFn = (req, next) => {
  const token = inject(TokenService);
  const auth = inject(AuthService);

  const accessToken = token.accessToken;
  const authRequired = req.url.search(API_PATH) > -1
    || req.url.search(PORTAL_API_PATH) > -1
    || req.url.search(`${AUTH_PATH}/api/userinfo`) > -1
    || req.url.search(`${AUTH_PATH}/oauth/token/revoke`) > -1;

  const handleUnauthorized = () => {
    auth.logout()
      .then(() => auth.toLogin())
      .catch(() => {});

    return EMPTY;
  }

  if (authRequired) {
    if (accessToken && !token.hasExpired) {
      const authReq = req.clone({
        setHeaders: {
          'Authorization': `Bearer ${accessToken}`
        }
      });

      return next(authReq).pipe(
        catchError(err => {
          if (err instanceof HttpErrorResponse && err.status === 401) {
            return handleUnauthorized();
          } else {
            return throwError(() => err);
          }
        })
      );
    } else {
      return handleUnauthorized();
    }
  } else {
    return next(req);
  }
};
