import { Component } from '@angular/core';
import { Router, RouterLink, RouterLinkActive, RouterOutlet } from '@angular/router';

import { MatButtonModule } from '@angular/material/button';
import { MatListModule } from '@angular/material/list';
import { SidenavMenuComponent, SidenavMenuLink } from '../../core/components/sidenav/sidenav-menu/sidenav-menu.component';
import { SidenavContainerComponent } from '../../core/components/sidenav/sidenav-container/sidenav-container.component';
import { fadeInOutPage } from '../../animations';

@Component({
  selector: 'iml-resources',
  standalone: true,
  imports: [
    SidenavMenuComponent,
    SidenavContainerComponent,
    RouterLink,
    RouterLinkActive,
    RouterOutlet,
    MatButtonModule,
    MatListModule,
  ],
  templateUrl: './resources.component.html',
  styleUrl: './resources.component.scss',
  animations: [fadeInOutPage],
})
export class ResourcesComponent {
  links: SidenavMenuLink[] = [
    {
      name: 'Resources',
      isExpanded: true,
      links: [
        { name: 'CPM Calculator', path: '/resources/cpm-calculator' },
        { name: 'SODA Builder', isExternal: true, path: 'https://soda.secureiml.com/' },
      ],
    },
    {
      name: 'Case Studies',
      path: '/resources/case-studies',
      links: [
        { name: 'How effective are Drivers?', path: '/resources/case-studies/case-study-1' },
        { name: 'Woman\'s Sportswear', path: '/resources/case-studies/case-study-2' },
        { name: 'Consumer Technology Store', path: '/resources/case-studies/case-study-3' },
        { name: 'Quick Service Restaraunt', path: '/resources/case-studies/case-study-4' },
      ],
    },
    {
      name: 'One sheeters',
      path: '/resources/one-sheeters',
      links: [
        { name: 'CRM Targeting', path: '/resources/one-sheeters/os-1' },
        { name: 'Collateral geofencing', path: '/resources/one-sheeters/os-2' },
      ],
    },
  ];

  constructor(private router: Router) {}

  getRouteAnimationState() {
    console.log('WOW', this.router.url);
    return this.router.url; // getRouteAnimationState(this.route);
  }

}
