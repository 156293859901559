import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';

import { PORTAL_API_PATH } from '../../../constants';
import { SortOrder, User } from '../campaigns/campaigns.service';

export enum CreativeRequestType {
  Creative = 'Creative',
  RFP = 'RFP',
}

export enum CreativeRequestStatus {
  New = 'New',
  Released = 'Released',
  Declined = 'Declined',
}

export enum AssetType {
  Proposal = 'Proposal',
  SODAs = 'SODAs',
  Banners = 'Banners',
  Driver = 'Driver',
}

export interface Asset {
  id: string;
  type: AssetType;
  name: string;
  url: string;
  isReleased?: boolean;
}

export interface Creative {
  id: string;
  name: string;
  type: CreativeRequestType;
  date: Date;
  updatedAt: Date;
  status: CreativeRequestStatus;
  assets: Asset[];
  submittedBy?: User; // TODO: replace by campaign order/link in the future
}

export interface CreativeOkResponse {
  creatives: Creative[],
  next?: string,
}

@Injectable({
  providedIn: 'root'
})
export class CreativesService {
  constructor(private httpClient: HttpClient) { }

  async assets(
    from: Date,
    to: Date,
    sortBy: string | null = null,
    sortOrder = SortOrder.Desc,
    next: string,
  ) {
    const url = `${PORTAL_API_PATH}/creatives`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      params: new HttpParams().appendAll({
        from: from.toISOString(),
        to: to.toISOString(),
      })
    };

    console.log('SORT?????', sortBy, sortOrder);
    if (sortBy) {
      httpOptions.params = httpOptions.params
        .append('sort_by', sortBy)
        .append('order_by', sortOrder);
    }

    if (next) {
      httpOptions.params = httpOptions.params
        .append('next', next);
    }

    const campaignSource$ = this.httpClient.get<CreativeOkResponse>(url, httpOptions);
    const response = await firstValueFrom(campaignSource$);

    return response;
  }
}
