import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';

import { PORTAL_API_PATH } from '../../../constants';
import { CampaignOkResponse, SortOrder } from '../campaigns/campaigns.service';

export interface SearchResource {
  name: string;
  category?: string;
  url: string;
  page?: number;
  content: string;
};

const resources: SearchResource[] = [
  {
    name: 'CPM Calculator',
    url: '/resources/cpm-calculator',
    content: `Impressions and Budget Forecast Tool
Retail Budget
0
Margin (%)
0
Retail Budget:	-
Margin Percentage:	-
Wholesale Budget:	-
Wholesale Budget
0
Total cost of a campaign in USD
Product
Display Banners
Targeting Level
Broad
Impressions Forecast
0
Wholesale Budget:	-
Product:	Display Banners
Targeting Level:	Broad
Impressions Forecast:	-`
  },
  {
    name: 'How effective are Drivers?',
    category: 'Case Studies',
    url: '/resources/case-studies/case-study-1',
    page: 1,
    content: `CASE STUDY:
How effective are Drivers?
Summary: We conducted a study to reveal the impact of Drivers on
a campaign's CPM and CPC.
Wait a second, why would including a Driver have any impact on a
campaign's CPM and CPC?
Google, Facebook, and other Ad Networks reward advertisers with
landing pages that follow their quality and creative guidelines, and
penalize advertisers that do not. A campaign's landing page can
actually determine as much as 30% of its "Quality Score"
, which is
the major factor in determining a campaign's CPM and CPC.
Ok, so what were the results? Can a landing page really have that
much of an effect on CPM and CPC?
The results were a resounding yes.
Results
Campaigns Analyzed
Media Spend
Impressions
Clicks
CPM
CPC
Non-Driver
65
$51,945.91
23,241,997
120,123
$2.23
$0.43
Driver
52
$18,625.18
10,074,448
53,528
$1.84
$0.35
CPM
17.4%
Driver's decrease CPM's on
average by 17.4%. For a campaign
that spends $1,000 that's an extra
95,000+ impressions on average!
18.6%
Driver's decrease CPC's on
average by 18.6%. For a campaign
that spends $1,000 that's an extra
500+ clicks on average!
CPC
Want to learn more about the
benefits of using a Driver? Give
us a shout! We're constantly
rolling out new features to help
your campaign's increase their
efficiency and campaign
performance.
`
  },
  {
    name: 'CRM Targeting',
    category: 'One sheeters',
    url: '/resources/one-sheeters/os-1',
    page: 1,
    content: `Digital Best Practices
CRM Targeting
CRM targeting leverages advertiser data such as names, addresses, phone numbers and email
addresses to identify and target specific mobile devices. IML translates the customer data and
matches it with the corresponding mobile device ID.
These audiences, based upon the gathered device IDs, will now receive the advertiser's ads as
they browse content and apps during the course of the campaign. Insight Media Labs CRM
targeting provides the most customized delivery because it leverages advertisers CRM data.
Right for:
Auto Retail Real Estate Restaurants Travel
Retirement Home Services Professional Services B2B
Targeting Opportunities
CRM Targeting requires the advertiser to
provide an excel spreadsheet and may include:
• Contact Names
• Company Name
• Email Addresses
• Physical Addresses
• Phone Numbers
Note: any combination of data may be included
Questions to Ask
• How are you marketing to your CRM data?
• Are your email lists suffering from fatigue?
• What would you say if we could send digital
ads to your CRM data?
• How much of your business is generated
from your existing customers?
The Rules
• CRM data must be delivered 7 days prior
to campaign launch
• A minimum of 250 lines of data must be
included. The more data the better.
• A minimum $1500 wholesale budget per
month is required
Tips & Best Practices
• Add as much CRM data as possible, more is better
• Think Mobile-First: The campaign will be mobile specific
• Small screens use active copy and bold offers
• Call-to-action should be short, be unique and grab
attention
• Remember that users receiving the campaign already
know the advertiser, an opportunity to be more specific
and personal
• These are the contacts the advertiser wants to remain in
continuous contact with so keep the campaign live for
long-term flights.`
  }
];

@Injectable({
  providedIn: 'root'
})
export class SearchService {
  constructor(private httpClient: HttpClient) { }

  async campaigns(
    query: string,
    from: Date,
    to: Date,
    sortBy: string | null = null,
    sortOrder = SortOrder.Desc,
  ) {
    const url = `${PORTAL_API_PATH}/campaigns/search`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      params: new HttpParams().appendAll({
        q: query,
        from: from.toISOString(),
        to: to.toISOString(),
      })
    };

    console.log('SORT?????', sortBy, sortOrder);
    if (sortBy) {
      httpOptions.params = httpOptions.params
        .append('sort_by', sortBy)
        .append('order_by', sortOrder);
    }

    const campaignSource$ = this.httpClient.get<CampaignOkResponse>(url, httpOptions);
    const response = await firstValueFrom(campaignSource$);

    return response;
  }

  async resources(query: string) {
    const resources = await this.getResources(query);
    console.log('RESOURCES', query, resources);

    return resources;
  }

  async getResources(query: string) {
    const keywords = query.trim().replace(/\s+/gi, ' ').replace(
      /[\[\]<>(){}|!'"*&?+\/:=\-\\~^]/g,
      (match) => `\\${match}`,
    ).split(' ').map(w => w.toLowerCase());
    const regex = new RegExp(keywords.join('|'), 'gmi');
    const searchedResources: SearchResource[] = [];

    for (const resource of resources) {
      let hasMatch = false;

      const { name, category, url, page, content } = resource;
      const matchedResource: SearchResource = { name, category, url, page, content };

      matchedResource.name = name.replace(regex, (match) => {
        hasMatch = true;
        return `<b>${match}</b>`;
      });
      matchedResource.content = content.replace(regex, (match) => {
        hasMatch = true;
        return `<b>${match}</b>`;
      });
      if (category) {
        matchedResource.category = category.replace(regex, (match) => {
          hasMatch = true;
          return `<b>${match}</b>`;
        });
      }

      if (hasMatch) {
        searchedResources.push(matchedResource);
      }
    }

    return searchedResources;
  }
}
