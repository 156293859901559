import { Component } from '@angular/core';
import { ChildrenOutletContexts, RouterLink, RouterLinkActive, RouterOutlet } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatRippleModule } from '@angular/material/core';

import { SidenavMenuComponent, SidenavMenuLink } from '../core/components/sidenav/sidenav-menu/sidenav-menu.component';
import { SidenavContainerComponent } from '../core/components/sidenav/sidenav-container/sidenav-container.component';
import { HeaderComponent } from '../core/components/header/header.component';
import { FooterComponent } from '../core/components/footer/footer.component';
import { UserService } from '../core/services/user.service';
import { MenuService } from '../core/services/menu.service';
import { ScreenService } from '../core/services/screen.service';

export interface NavigationRailLink {
  name: string;
  icon: string;
  path: string;
  links?: SidenavMenuLink[];
};

@Component({
  selector: 'iml-pages',
  standalone: true,
  imports: [
    SidenavMenuComponent,
    SidenavContainerComponent,
    RouterLink,
    RouterLinkActive,
    RouterOutlet,
    MatIconModule,
    MatButtonModule,
    MatToolbarModule,
    MatSidenavModule,
    MatRippleModule,
    HeaderComponent,
    FooterComponent,
  ],
  templateUrl: './pages.component.html',
  styleUrl: './pages.component.scss',
})
export class PagesComponent {
  currentYear = new Date().getFullYear();
  opened = false;
  pageMenuOpened = false;
  isFocusedOnRail = false;
  isFocusedOnMenu = false;

  links: SidenavMenuLink[] = [
    { name: 'Campaigns', path: '/campaigns', icon: 'monitoring' }, // campaign // analytics
    { name: 'Assets', path: '/creatives', icon: 'newspaper' }, // dashboard // RFPs and Creatives // Creatives
    // gallery_thumbnail // newspaper // newsmode // art_track // planner_banner_ad_pt // ad_units // dashboard
    {
      name: 'New Request',
      icon: 'order_approve',
      path: '/submissions',
      links: [
        {
          name: 'Requests',
          isExpanded: true,
          links: [
            { name: 'Submit RFP', path: '/submissions/requests/rfp' },
            { name: 'Submit Creative', path: '/submissions/requests/creative' },
            { name: 'Request Dashboard', path: '/submissions/requests/dashboard' },
          ],
        },
        {
          name: 'Orders',
          isExpanded: true,
          links: [
            { name: 'Display Campaign', path: '/submissions/orders/display-campaign' },
            { name: 'Video Campaign', path: '/submissions/orders/video-campaign' },
            { name: 'Audio Campaign', path: '/submissions/orders/audio-campaign' },
            { name: 'NewsDriver Campaign', path: '/submissions/orders/newsdriver-campaign' },
            { name: 'Search Campaign', path: '/submissions/orders/search-campaign' },
            { name: 'DOOH Campaign', path: '/submissions/orders/dooh-campaign' },
          ],
        },
      ],
    },
    {
      name: 'Resources',
      icon: 'school',
      path: '/resources',
      links: [
        {
          name: 'Resources',
          isExpanded: true,
          links: [
            { name: 'CPM Calculator', path: '/resources/cpm-calculator' },
            { name: 'SODA Builder', isExternal: true, path: 'https://soda.secureiml.com/' },
          ],
        },
        {
          name: 'Case Studies',
          path: '/resources/case-studies',
          links: [
            { name: 'How effective are Drivers?', path: '/resources/case-studies/case-study-1' },
            { name: 'Woman\'s Sportswear', path: '/resources/case-studies/case-study-2' },
            { name: 'Consumer Technology Store', path: '/resources/case-studies/case-study-3' },
            { name: 'Quick Service Restaraunt', path: '/resources/case-studies/case-study-4' },
          ],
        },
        {
          name: 'One sheeters',
          path: '/resources/one-sheeters',
          links: [
            { name: 'CRM Targeting', path: '/resources/one-sheeters/os-1' },
            { name: 'Collateral Geofencing', path: '/resources/one-sheeters/os-2' },
          ],
        },
      ],
    },
  ];

  // sublinks: { [key: string]: SidenavMenuLink[] } = {};
  sublinks: SidenavMenuLink[] = [];
  // links = [
  //   { name: 'Campaigns', path: '/campaigns', icon: 'monitoring' }, // campaign // analytics
  //   { name: 'Assets', path: '/creatives', icon: 'planner_banner_ad_pt' }, // dashboard // RFPs and Creatives // Creatives
  //   // gallery_thumbnail // newspaper // newsmode // art_track // planner_banner_ad_pt // ad_units // dashboard
  //   { name: 'New Request', path: '/submissions', icon: 'order_approve' }, // construction // list // Request & Order // Request/Order
  //   { name: 'Resources', path: '/resources', icon: 'school' }, // construction // Resources & Help // Help

  //   // { name: 'Get Started', path: '/campaigns', icon: 'apps' }, // campaign // analytics
  //   // { name: 'Develop', path: '/creatives', icon: 'code' }, // RFPs and Creatives // Creatives
  //   // // gallery_thumbnail // newspaper // newsmode // art_track // planner_banner_ad_pt // ad_units // dashboard
  //   // { name: 'Foundations', path: '/submissions', icon: 'book' }, // list // Request & Order // Request/Order
  //   // { name: 'Styles', path: '/resources', icon: 'palette' }, // Resources & Help // Help

  //   // { name: 'Campaigns', path: '/campaigns', icon: 'monitoring' }, // campaign
  //   // { name: 'RFPs and Creatives', path: '/creatives', icon: 'dashboard' }, // RFPs and Creatives // Creatives
  //   // // gallery_thumbnail // newspaper // newsmode // art_track // planner_banner_ad_pt // ad_units // dashboard
  //   // { name: 'Request & Order', path: '/submissions', icon: 'construction' }, // list // Request & Order // Request/Order
  //   // { name: 'Resources & Help', path: '/resources', icon: 'help_center' }, // Resources & Help // Help
  // ];
  constructor(
    private contexts: ChildrenOutletContexts,
    private user: UserService,
    public screen: ScreenService,
    public menu: MenuService
  ) {
    // for (const link of this.links) {
    //   if (Array.isArray(link.links)) {
    //     this.sublinks[link.path] = link.links;
    //   }
    // }
    // this.user.requestUserInfo().then((r) => console.log('USER', ));
  }

  closeMenu() {
    this.toggleMenu();
  }

  openSubMenu() {
    this.opened = true;
    this.menu.isMainMenuOpened.next(this.opened);
  }

  closeSubMenu() {
    this.opened = false;
    this.menu.isMainMenuOpened.next(this.opened);
  }

  toggleMenu() {
    if (this.menu.hasPageMenu.value) {
      this.menu.togglePageMenu();
    } else {
      this.opened = !this.opened;
      this.menu.isMainMenuOpened.next(this.opened);
    }
  }

  getRouteAnimationData() {
    console.log('WOW', this.contexts.getContext('primary')?.route?.snapshot?.title);
    return this.contexts.getContext('primary')?.route?.snapshot?.title; // .data?.['animation'];
  }

  onRailMouseEnter(index: number) {
    this.isFocusedOnRail = true;
    this.isFocusedOnMenu = false;

    console.log('WHAT', index);
    if (Array.isArray(this.links[index].links)) {
      this.sublinks = this.links[index].links as any; // TODO: fix
      this.opened = true;
    } else {
      this.opened = false;
    }
  }

  onMenuMouseEnter() {
    console.log('MOUSE ENTER');
    this.isFocusedOnMenu = true;
    this.isFocusedOnRail = false;
  }

  onRailMouseLeave() {
    this.isFocusedOnRail = false;
    console.log('MOUSE LEAVE');
    setTimeout(() => {
      if (this.opened && !this.isFocusedOnMenu && !this.isFocusedOnRail) {
        this.opened = false;
      }
      console.log('LEAVE 11111', this.opened, this.isFocusedOnMenu, this.isFocusedOnRail);
    }, 100);
  }

  onMenuMouseLeave() {
    console.log('MOUSE LEAVE');
    setTimeout(() => {
      if (this.opened && !this.isFocusedOnMenu && !this.isFocusedOnRail) {
        this.opened = false;
      }

      this.isFocusedOnMenu = false;
    }, 100);
  }
}
