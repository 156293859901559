import { Component, Input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'iml-case-studies',
  standalone: true,
  imports: [],
  templateUrl: './case-studies.component.html',
  styleUrl: './case-studies.component.scss'
})
export class CaseStudiesComponent {
  caseStudies: { [key: string]: string } = {
    'case-study-1': 'https://newsdriver-prod-media.s3-us-west-2.amazonaws.com/console/Case+Study_+How+Effective+Are+Drivers+(1)+(1)+copy.pdf',
    'case-study-2': 'https://newsdriver-prod-media.s3-us-west-2.amazonaws.com/console/Case+Study_+Woman%CE%93%C3%87%C3%96s+Sportswear+copy.pdf',
    'case-study-3': 'https://newsdriver-prod-media.s3-us-west-2.amazonaws.com/console/Case+Study_Consumer+Technology+Store+copy.pdf',
    'case-study-4': 'https://newsdriver-prod-media.s3-us-west-2.amazonaws.com/console/Case+Study_Quick+Service+Restaurant+copy.pdf',
  };

  selectedStudy = this.sanitizer.bypassSecurityTrustResourceUrl(this.caseStudies['case-study-1']);

  @Input()
  set id(id: string) {
    console.log('ID', id);
    let url = this.caseStudies['case-study-1'];

    if (this.caseStudies[id]) {
      url = this.caseStudies[id];
    }

    this.selectedStudy = this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  constructor(private sanitizer: DomSanitizer) {}
}
