import { Component, Input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'iml-one-sheeters',
  standalone: true,
  imports: [],
  templateUrl: './one-sheeters.component.html',
  styleUrl: './one-sheeters.component.scss'
})
export class OneSheetersComponent {
  caseStudies: { [key: string]: string } = {
    'os-1': 'https://newsdriver-prod-media.s3-us-west-2.amazonaws.com/console/IML-CRM-Capabilities.pdf',
    'os-2': 'https://newsdriver-prod-media.s3-us-west-2.amazonaws.com/console/IML-Collateral-Geofencing-v2+copy.pdf',
  };

  selectedDoc = this.sanitizer.bypassSecurityTrustResourceUrl(this.caseStudies['os1-1']);

  @Input()
  set id(id: string) {
    console.log('ID', id);
    let url = this.caseStudies['os-1'];

    if (this.caseStudies[id]) {
      url = this.caseStudies[id];
    }

    this.selectedDoc = this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  constructor(private sanitizer: DomSanitizer) {}
}
